<template>
  <div class="page-wrapper">
    <div class="community-pc">
      <h1>커뮤니티 목록</h1>
    </div>

    <div class="article">
      <div class="filter">filter</div>
      <ul>
        <li v-for="n in 100" :key="`index-${n}`">
          <h1>{{ `테스트 ${n}` }}</h1>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "LabsSticky",
};
</script>

<style scoped>
.page-wrapper {
  display: flex;
  overflow: auto;
  flex-direction: row-reverse;
  gap: 24px;
  padding: 0;
}

.page-wrapper .article {
  background: red;
  min-width: 654px;
  width: 66%;
}

.page-wrapper .community-pc {
  position: sticky;
  top: 0;
  align-self: flex-start;
  display: inline-block;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  width: 32%;
  min-width: 314px;
  margin: 0;
  height: fit-content;
}

.filter {
  position: sticky;
  top: 0;
}
</style>
